import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, updateDoc, doc, setDoc } from 'firebase/firestore';
import { firestore } from '../../../config/firebaseConfig';
import { 
  Card, 
  CardContent, 
  Typography, 
  Container, 
  Grid, 
  Alert, 
  CircularProgress, 
  Box, 
  Chip,
  TextField,
  Button,
  Fade,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Snackbar,
  Rating,
} from '@mui/material';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { t } from 'i18next';

const UserOrders = ({ customerUid }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orderCode, setOrderCode] = useState('');
  const [openAddOrder, setOpenAddOrder] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [feedbackEligibleOrders, setFeedbackEligibleOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const ordersCollection = collection(firestore, 'orders');
        const q = query(ordersCollection, where('customerUid', '==', customerUid));
        const querySnapshot = await getDocs(q);
        const ordersList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setOrders(ordersList);
        
        // Identify orders eligible for feedback
        const eligibleOrders = ordersList.filter(order => 
          order.status.toLowerCase() === 'delivered' && !order.feedbackProvided
        );
        setFeedbackEligibleOrders(eligibleOrders);
      } catch (err) {
        setError('Failed to fetch orders');
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [customerUid]);

  const handleAddOrder = async () => {
    if (!orderCode.trim()) {
      setSnackbar({ open: true, message: 'Please enter an order code.', severity: 'error' });
      return;
    }

    try {
      const ordersCollection = collection(firestore, 'orders');
      const q = query(ordersCollection, where('orderCode', '==', orderCode.trim()));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setSnackbar({ open: true, message: 'Order not found.', severity: 'error' });
        return;
      }

      const orderDoc = querySnapshot.docs[0];
      const orderData = orderDoc.data();

      if (orderData.customerUid && orderData.customerUid!=="N/A") {
        setSnackbar({ open: true, message: 'This order is already associated with another user.', severity: 'error' });
        return;
      }

      await updateDoc(doc(firestore, 'orders', orderDoc.id), { customerUid });

      setSnackbar({ open: true, message: 'Order added to your account successfully.', severity: 'success' });
      setOrderCode(''); 
    
      const newOrders = orders.concat({ ...orderData, customerUid });
      setOrders(newOrders);
      setOpenAddOrder(false);
    } catch (err) {
      setSnackbar({ open: true, message: 'Failed to add order to your account.', severity: 'error' });
    }
  };

  // const handleFeedbackSubmit = async (orderId, feedback, rating) => {
  //   try {
  //     // Update the order document
  //     await updateDoc(doc(firestore, 'orders', orderId), { 
  //       feedbackProvided: true 
  //     });

  //     // Add feedback to the feedback collection
  //     const feedbackRef = doc(firestore, 'feedback', customerUid);
  //     await setDoc(feedbackRef, {
  //       message: feedback,
  //       rating: rating,
  //       timestamp: new Date(),
  //       userId: customerUid,
  //       place: 'order',
  //     }, { merge: true });

  //     setSnackbar({ open: true, message: 'Feedback submitted successfully.', severity: 'success' });
  //     setFeedbackEligibleOrders(prev => prev.filter(order => order.id !== orderId));
  //     setOrders(prev => prev.map(order => 
  //       order.id === orderId ? { ...order, feedbackProvided: true } : order
  //     ));
  //   } catch (err) {
  //     setSnackbar({ open: true, message: 'Failed to submit feedback.', severity: 'error' });
  //   }
  // };

  const getStatusColor = (status) => {
    switch(status.toLowerCase()) {
      case 'delivered': return '#4caf50';
      case 'pending': return '#ff9800';
      case 'cancelled': return '#f44336';
      default: return '#9e9e9e';
    }
  };

  const FeedbackPrompt = ({ order, onSubmit }) => {
    const [feedback, setFeedback] = useState('Merci beaucoup 5edma mnadhma w ya3tikom sa77a feli ta3mlo fih 🥰');
    const [rating, setRating] = useState(5);
  
    const handleSubmit = () => {
      onSubmit(order.id, feedback, rating);
      setFeedback('');
      setRating(5);
    };
  
    return (
      <Box mt={2}>
        <Typography variant="subtitle1">How was your experience with this order?</Typography>
        <TextField
          fullWidth
          multiline
          rows={3}
          variant="outlined"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          margin="normal"
        />
        <Box display="flex" alignItems="center" mb={2}>
          <Typography component="legend">Rating:</Typography>
          <Rating
            name="simple-controlled"
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
          />
        </Box>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleSubmit}
          disabled={!feedback.trim()}
        >
          Submit Feedback
        </Button>
      </Box>
    );
  };

  if (loading) return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
      <CircularProgress style={{ color: '#FF9800' }} />
    </Box>
  );

  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <Typography variant="h4" fontWeight="bold" style={{ color: '#FF9800' }}>
          {t('your_orders')}
        </Typography>
        <Button
          variant="contained"
          style={{ backgroundColor: '#FF9800', color: 'white' }}
          startIcon={<AddIcon />}
          onClick={() => setOpenAddOrder(true)}
        >
          {t('add_order')}
        </Button>
      </Box>

      <Grid container spacing={3}>
        {orders.map(order => (
          <Grid item xs={12} sm={6} md={4} key={order.id}>
            <Fade in={true} timeout={500}>
              <Card 
                elevation={2}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
                  },
                  borderRadius: 2,
                  overflow: 'hidden',
                }}
              >
                <Box sx={{ p: 2, backgroundColor: '#FFF3E0', color: '#E65100' }}>
                  <Typography variant="h6" fontWeight="bold">
                    # {order.orderCode}
                  </Typography>
                  <Typography variant="body2">
                    {new Date(order.orderDate.toDate()).toLocaleDateString()}
                  </Typography>
                </Box>
                <CardContent sx={{ flexGrow: 1, p: 2 }}>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Chip 
                      label={order.status} 
                      size="small"
                      style={{
                        backgroundColor: getStatusColor(order.status),
                        color: 'white',
                      }}
                    />
                    <Typography variant="h6" fontWeight="bold" style={{ color: '#F44336' }}>
                      {order.priceInTnd} DT
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary" mb={1}>
                    First Pay Check: {order.clientFirstPaycheck} Dt
                  </Typography>
                  <Typography variant="body2" color="text.secondary" mb={1}>
                    Rest: {order.priceInTnd - order.clientFirstPaycheck} Dt
                  </Typography>
                  <Typography variant="body2" color="text.secondary" mb={1}>
                    Payment: {order.paymentMethod}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Site: {order.site}
                  </Typography>
                </CardContent>
                <Box sx={{ p: 2, backgroundColor: '#f5f5f5', textAlign: 'right' }}>
                  <IconButton size="small" style={{ color: '#FF9800' }}>
                    <ShoppingBagOutlinedIcon />
                  </IconButton>
                </Box>
                {/* {feedbackEligibleOrders.some(eligibleOrder => eligibleOrder.id === order.id) && (
                  <FeedbackPrompt order={order} onSubmit={handleFeedbackSubmit} />
                )} */}
              </Card>
            </Fade>
          </Grid>
        ))}
      </Grid>

      <Dialog open={openAddOrder} onClose={() => setOpenAddOrder(false)} fullWidth maxWidth="sm">
        <DialogTitle>Add New Order</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="orderCode"
            label="Order Code"
            type="text"
            fullWidth
            variant="outlined"
            value={orderCode}
            onChange={(e) => setOrderCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddOrder(false)} color="inherit">Cancel</Button>
          <Button onClick={handleAddOrder} variant="contained" style={{ backgroundColor: '#FF9800', color: 'white' }}>Add Order</Button>
        </DialogActions>
      </Dialog>
      
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
        action={
          <IconButton size="small" color="inherit" onClick={() => setSnackbar({ ...snackbar, open: false })}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default UserOrders;