import React, { useState, useMemo } from 'react';
import { AppBar, Toolbar, Typography, Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, useMediaQuery, Box, CssBaseline, Switch, FormControlLabel, Paper, Grid, Avatar, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PeopleIcon from '@mui/icons-material/People';
import BackupIcon from '@mui/icons-material/Backup';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import BarChartIcon from '@mui/icons-material/BarChart';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';

const drawerWidth = 240;
const closedDrawerWidth = 65;

const menuItems = [
  { text: 'Order Management', icon: <ShoppingCartIcon /> },
  { text: 'Transactions Management', icon: <AccountBalanceIcon /> },
  { text: 'Users Management', icon: <PeopleIcon /> },
  { text: 'Backup Management', icon: <BackupIcon /> },
  { text: 'Report Management', icon: <AssessmentIcon /> },
  { text: 'Settings Management', icon: <SettingsIcon /> },
  { text: 'Statistics Management', icon: <BarChartIcon /> },
];

const MotionListItem = motion(ListItem);

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  background: theme.palette.background.paper,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
  },
}));

const AdminDashboardV2 = () => {
  const [open, setOpen] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [mode, setMode] = useState('light');

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: '#FF8C00', // Orange
            light: '#FFA500',
            dark: '#FF7000',
          },
          secondary: {
            main: '#FFFFFF', // White
          },
          error: {
            main: '#FF4444', // Red accent
          },
          background: {
            default: mode === 'light' ? '#F5F5F5' : '#121212',
            paper: mode === 'light' ? '#FFFFFF' : '#1E1E1E',
          },
        },
        typography: {
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          h6: {
            fontWeight: 600,
          },
        },
        components: {
          MuiDrawer: {
            styleOverrides: {
              paper: {
                backgroundColor: mode === 'light' ? '#FFFFFF' : '#121212',
                color: mode === 'light' ? '#333333' : '#FFFFFF',
              },
            },
          },
          MuiListItem: {
            styleOverrides: {
              root: {
                '&.Mui-selected': {
                  backgroundColor: mode === 'light' ? 'rgba(255, 140, 0, 0.1)' : 'rgba(255, 140, 0, 0.2)',
                  '&:hover': {
                    backgroundColor: mode === 'light' ? 'rgba(255, 140, 0, 0.2)' : 'rgba(255, 140, 0, 0.3)',
                  },
                },
              },
            },
          },
        },
      }),
    [mode],
  );

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };

  const drawer = (
    <div>
      <Toolbar sx={{ justifyContent: 'flex-end', minHeight: '64px !important' }}>
        <IconButton onClick={handleDrawerToggle}>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Toolbar>
      <List>
        {menuItems.map((item, index) => (
          <Tooltip title={open ? '' : item.text} placement="right" key={item.text}>
            <MotionListItem
              button
              selected={selectedIndex === index}
              onClick={() => handleListItemClick(index)}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              sx={{
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: theme.palette.primary.main,
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} sx={{ opacity: open ? 1 : 0 }} />
            </MotionListItem>
          </Tooltip>
        ))}
      </List>
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${open ? drawerWidth : closedDrawerWidth}px)` },
            ml: { sm: `${open ? drawerWidth : closedDrawerWidth}px` },
            boxShadow: 'none',
            backgroundImage: 'linear-gradient(45deg, #FF8C00 30%, #FFA500 90%)',
            transition: theme.transitions.create(['margin', 'width'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          }}
        >
          <Toolbar>
            <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, fontWeight: 'bold', color: 'white' }}>
              Admin Dashboard
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={mode === 'dark'}
                  onChange={colorMode.toggleColorMode}
                  color="default"
                />
              }
              label={mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            />
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
          sx={{
            width: open ? drawerWidth : closedDrawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: open ? drawerWidth : closedDrawerWidth,
              boxSizing: 'border-box',
              overflowX: 'hidden',
              transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            },
          }}
        >
          {drawer}
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${open ? drawerWidth : closedDrawerWidth}px)` },
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          }}
        >
          <Toolbar />
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
            {menuItems[selectedIndex].text}
          </Typography>
          <Grid container spacing={3}>
            {[1, 2, 3, 4].map((item) => (
              <Grid item xs={12} sm={6} md={3} key={item}>
                <StyledPaper>
                  <Avatar sx={{ width: 56, height: 56, margin: 'auto', bgcolor: theme.palette.primary.main }}>
                    {menuItems[selectedIndex].icon}
                  </Avatar>
                  <Typography variant="h6" sx={{ mt: 2, color: theme.palette.primary.dark }}>
                    Metric {item}
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    Some relevant data for {menuItems[selectedIndex].text}
                  </Typography>
                </StyledPaper>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default AdminDashboardV2;