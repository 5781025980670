import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { firestore } from '../../../config/firebaseConfig'; // Ensure the correct path
import './newsletter-signup.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NewsletterSignupSection = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const isValidEmail = (email) => {
    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSignup = async () => {
    if (email) {
      if (!isValidEmail(email)) {
        toast.warning('Please enter a valid email address.', {
          position: 'top-center',
          autoClose: 3000,
        });
        return;
      }
      
      setLoading(true); // Start loading
      try {
        console.log('Attempting to add email:', email);

        // Add email to Firestore
        await addDoc(collection(firestore, 'newsletter'), {
          mail: email,
        });

        console.log('Email added successfully');
        toast.success('شكراً لاشتراكك!', {
          position: 'top-center',
          autoClose: 3000,
        });
        setEmail(''); // Clear the input field
      } catch (error) {
        console.error('Error adding document:', error.message);
        toast.error('Something went wrong. Please try again.', {
          position: 'top-center',
          autoClose: 3000,
        });
      } finally {
        setLoading(false); // Stop loading
      }
    } else {
      toast.warning('Please enter a valid email address.', {
        position: 'top-center',
        autoClose: 3000,
      });
    }
  };

  return (
    <section className="newsletter_signup_section">
      <div className="heading_container">
        <h2>اشترك و احصل على آخر العروض والخدمات المميزة</h2>
      </div>
      <div className="signup_container">
        <input
          type="email"
          placeholder="أدخل بريدك الإلكتروني"
          className="email_input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button className="signup_button" onClick={handleSignup} disabled={loading}>
          {loading ? (
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          ) : (
            'اشترك'
          )}
        </button>
      </div>
      <ToastContainer />
    </section>
  );
};

export default NewsletterSignupSection;
