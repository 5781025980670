import React, { useState, useEffect } from 'react';
import { firestore as db, storage } from '../../config/firebaseConfig';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const AddProduct = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState(0);
  const [category, setCategory] = useState('');
  const [sizes, setSizes] = useState([]);
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  const categories = [
    'Clothing', 'Shoes', 'Accessories', 'Lingerie', 'Bags', 'Jewelry',
    'Beauty Products', 'Sportswear', 'Swimwear', 'Nightwear', 'Home & Living',
    'Electronics', 'Toys', 'Books',
  ];

  const clothingSizes = ['XS', 'S', 'M', 'L', 'XL', 'XXL'];
  const shoeSizes = ['36', '37', '38', '39', '40', '41', '42', '43', '44'];

  useEffect(() => {
    console.log('Category state updated:', category);
    console.log('Sizes state updated:', sizes);
  }, [category, sizes]);

  const generateUniqueId = () => {
    const letters = 'abcdefghijklmnopqrstuvwxyz';
    const firstLetter = letters[Math.floor(Math.random() * letters.length)];
    const lastLetter = letters[Math.floor(Math.random() * letters.length)];
    const numbers = Math.floor(Math.random() * 1000000).toString().padStart(6, '0');
    return `${firstLetter}${numbers}${lastLetter}`;
  };

  const isIdUnique = async (id) => {
    const q = query(collection(db, 'products'), where('itemId', '==', id));
    const querySnapshot = await getDocs(q);
    return querySnapshot.empty;
  };

  const getUniqueId = async () => {
    let id = generateUniqueId();
    while (!(await isIdUnique(id))) {
      id = generateUniqueId();
    }
    return id;
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(files);
  };

  const uploadImages = async (itemId) => {
    const uploadPromises = images.map(async (image) => {
      const storageRef = ref(storage, `products/${itemId}/${image.name}`);
      await uploadBytes(storageRef, image);
      return getDownloadURL(storageRef);
    });

    return Promise.all(uploadPromises);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);

    try {
      const itemId = await getUniqueId();
      const imageUrls = await uploadImages(itemId);

      await addDoc(collection(db, 'products'), {
        itemId,
        name,
        description,
        price: Number(price),
        images: imageUrls,
        category,
        sizes: category === 'Clothing' || category === 'Shoes' ? sizes : [],
        quantity: Number(quantity),
        isVisible,
      });

      console.log('Product added to Firestore successfully');

      setName('');
      setDescription('');
      setPrice(0);
      setImages([]);
      setCategory('');
      setSizes([]);
      setQuantity(0);
      setIsVisible(true);
    } catch (error) {
      console.error('Error adding product to Firestore:', error);
    } finally {
      setUploading(false);
    }
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setCategory(selectedCategory);
    setSizes([]);
    console.log('Chosen category:', selectedCategory);
  };

  const handleSizeChange = (size) => {
    setSizes(prevSizes => {
      if (prevSizes.includes(size)) {
        return prevSizes.filter(s => s !== size);
      } else {
        return [...prevSizes, size];
      }
    });
  };

  return (
    <div className="add-product-form">
      <h2>Add a New Product</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="description">Description:</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
        </div>
        <div>
          <label htmlFor="price">Price:</label>
          <input
            type="number"
            id="price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="quantity">Quantity:</label>
          <input
            type="number"
            id="quantity"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="isVisible">Visibility:</label>
          <input
            type="checkbox"
            id="isVisible"
            checked={isVisible}
            onChange={(e) => setIsVisible(e.target.checked)}
          />
          <span>Product is visible</span>
        </div>
        <div>
          <label htmlFor="images">Images:</label>
          <input
            type="file"
            id="images"
            onChange={handleImageChange}
            multiple
            accept="image/*"
            required
          />
        </div>
        <div>
          <label htmlFor="category">Category:</label>
          <select 
            id="category" 
            value={category} 
            onChange={handleCategoryChange} 
            required
          >
            <option value="">Select a category</option>
            {categories.map((cat) => (
              <option key={cat} value={cat}>{cat}</option>
            ))}
          </select>
        </div>

        {(category === 'Clothing' || category === 'Shoes') && (
          <div>
            <label>Sizes:</label>
            <div className="size-checkboxes">
              {(category === 'Clothing' ? clothingSizes : shoeSizes).map((sizeOption) => (
                <label key={sizeOption} className="size-checkbox">
                  <input
                    type="checkbox"
                    value={sizeOption}
                    checked={sizes.includes(sizeOption)}
                    onChange={() => handleSizeChange(sizeOption)}
                  />
                  {sizeOption}
                </label>
              ))}
            </div>
          </div>
        )}

        <button type="submit" disabled={uploading}>
          {uploading ? 'Uploading...' : 'Add Product'}
        </button>
      </form>
      <style jsx>{`
        .add-product-form {
          max-width: 500px;
          margin: 0 auto;
          padding: 20px;
          background-color: #f9f9f9;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
        h2 {
          text-align: center;
          color: #333;
          margin-bottom: 20px;
        }
        form {
          display: flex;
          flex-direction: column;
        }
        div {
          margin-bottom: 15px;
        }
        label {
          display: block;
          margin-bottom: 5px;
          color: #666;
        }
        input, textarea, select {
          width: 100%;
          padding: 8px;
          border: 1px solid #ddd;
          border-radius: 4px;
          font-size: 16px;
        }
        textarea {
          height: 100px;
          resize: vertical;
        }
        button {
          padding: 10px 15px;
          background-color: #007bff;
          color: white;
          border: none;
          border-radius: 4px;
          font-size: 16px;
          cursor: pointer;
          transition: background-color 0.3s;
        }
        button:hover:not(:disabled) {
          background-color: #0056b3;
        }
        button:disabled {
          background-color: #cccccc;
          cursor: not-allowed;
        }
        @media (max-width: 600px) {
          .add-product-form {
            padding: 15px;
          }
        }
      `}</style>
    </div>
  );
};

export default AddProduct;