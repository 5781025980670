import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../config/firebaseConfig';
import { signOut } from 'firebase/auth';
import { ShoppingBag } from '@mui/icons-material';

const Header = ({ user }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate(); 
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
signOut(auth).then(()=>
  
  navigate('/')
);};


  return (
    <AppBar 
      position="static" 
      sx={{
        backgroundColor: '#f8842b', 
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
        borderRadius: '0 0 20px 20px', 
        transform: 'translateZ(0)',
        zIndex: (theme) => theme.zIndex.drawer + 1, 
      }} 
      dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
    >
      <Toolbar>
        {/* Left-aligned menu icon */}
        <IconButton 
          edge="start" 
          color="inherit" 
          aria-label={t('menu')}
          onClick={handleMenuOpen}
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        {/* <IconButton 
          edge="start" 
          color="inherit" 
          aria-label={t('menu')}
          href='/shop'
          sx={{ mr: 2 }}
        >
          <ShoppingBag />
        </IconButton> */}

        {/* Centered logo */}
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
          <a href='/'>
            <img 
              src="images/amine_jameli.png" 
              alt={t('logo_alt_text')} // Better for accessibility
              style={{ height: '50px', objectFit: 'contain' }} 
            />
          </a> 
        </Box>

        {/* Right-aligned user icon or sign-up button */}
        {user ? (
          <IconButton 
            edge="end" 
            color="inherit" 
            aria-label={t('user_menu')}
            href='/me'
          >
            <AccountCircle />
          </IconButton>
        ) : (
          <Button color="inherit" href="/me">
            {t('signup')}
          </Button>
        )}

        {/* Menu dropdown */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          PaperProps={{
            sx: {
              backgroundColor: '#fff',
              color: '#000',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
              borderRadius: '8px',
              mt: 1.5, 
            },
          }}
        >
          <MenuItem 
            onClick={handleMenuClose} 
            component="a" 
            href="/" 
            sx={{ borderRadius: '8px', '&:hover': { backgroundColor: '#f0f0f0' } }}
          >
            {t('home')}
          </MenuItem>
          <MenuItem 
            onClick={handleMenuClose} 
            component="a" 
            href="/tracking" 
            sx={{ borderRadius: '8px', '&:hover': { backgroundColor: '#f0f0f0' } }}
          >
            {t('trackOrder')}
          </MenuItem>
          <MenuItem 
            onClick={handleMenuClose} 
            component="a" 
            href="/feedbacks" 
            sx={{ borderRadius: '8px', '&:hover': { backgroundColor: '#f0f0f0' } }}
          >
            {t('client_feedbacks')}
          </MenuItem>
        
          {user && (
            <MenuItem 
              onClick={handleLogout} 
              sx={{ borderRadius: '8px', '&:hover': { backgroundColor: '#ffAA42' } }}
            >
              {t('logout')}
            </MenuItem>
          )}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
