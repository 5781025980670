import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { auth } from '../../../config/firebaseConfig';
import { sendPasswordResetEmail } from 'firebase/auth';
import LockResetIcon from '@mui/icons-material/LockReset';
import LanguageIcon from '@mui/icons-material/Language';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoIcon from '@mui/icons-material/Info';
import styles from './Settings.module.css';

const Settings = ({ user, userData }) => {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState(user?.email || '');
  const [language, setLanguage] = useState(i18n.language);
  const [snackbar, setSnackbar] = useState({ show: false, message: '', type: 'success' });

  useEffect(() => {
    if (snackbar.show) {
      const timer = setTimeout(() => {
        setSnackbar(prev => ({ ...prev, show: false }));
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [snackbar.show]);

  const handlePasswordReset = () => {
    if (!email) {
      setSnackbar({ show: true, message: t('please_provide_email'), type: 'error' });
      return;
    }
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSnackbar({ show: true, message: t('password_reset_email_sent'), type: 'success' });
      })
      .catch((err) => {
        console.error('Error sending password reset email:', err);
        setSnackbar({ show: true, message: t('error_sending_password_reset_email'), type: 'error' });
      });
  };

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    setSnackbar({ show: true, message: t('language_changed'), type: 'success' });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setSnackbar({ show: true, message: t('copied_to_clipboard'), type: 'success' });
    }).catch(err => {
      console.error('Failed to copy text: ', err);
      setSnackbar({ show: true, message: t('failed_to_copy'), type: 'error' });
    });
  };

  return (
    <div className={styles.settingsContainer}>
      <div className={styles.settingsCard}>
        <div className={styles.cardHeader}>
          <h2 className={styles.cardTitle}>{t('settings')}</h2>
        </div>
        <div className={styles.cardContent}>
          <section className={styles.formGroup}>
            <h3 className={styles.sectionTitle}>
              <InfoIcon /> {t('user_information')}
            </h3>
            <div className={styles.infoGroup}>
              <p className={styles.infoText}>
                <strong>{t('user_uid')}:</strong> {user.uid}
              </p>
              <button 
                className={styles.copyButton} 
                onClick={() => copyToClipboard(user.uid)}
                title={t('copy_uid')}
              >
                <ContentCopyIcon/>
              </button>
            </div>
            <p className={styles.infoText}>
              <strong>{t('user_shortcode')}:</strong> {userData.shortCode}
            </p>
          </section>

          <hr className={styles.divider} />

          <section className={styles.formGroup}>
            <h3 className={styles.sectionTitle}>
              <LockResetIcon /> {t('forgot_password')}
            </h3>
            <label className={styles.inputLabel} htmlFor="email">
              {t('email')}
            </label>
            <input
              id="email"
              className={styles.input}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled
            />
            <button className={styles.button} onClick={handlePasswordReset}>
              {t('reset_password')}
            </button>
          </section>

          <hr className={styles.divider} />

          <section className={styles.formGroup}>
            <h3 className={styles.sectionTitle}>
              <LanguageIcon /> {t('language_settings')}
            </h3>
            <label className={styles.inputLabel} htmlFor="language">
              {t('select_language')}
            </label>
            <select
              id="language"
              className={styles.select}
              value={language}
              onChange={handleLanguageChange}
            >
              <option value="en">{t('english')}</option>
              <option value="ar">{t('arabic')}</option>
            </select>
          </section>
        </div>
      </div>

      {snackbar.show && (
        <div className={`${styles.snackbar} ${styles[snackbar.type]}`}>
          {snackbar.message}
        </div>
      )}
    </div>
  );
};

export default Settings;