import React, { useState, useEffect } from 'react';
import {
  Box,
  CssBaseline,
  AppBar,
  Tabs,
  Tab,
  Toolbar,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Avatar,
  useMediaQuery,
  Container,
  Paper,
  IconButton,
  Badge,
} from '@mui/material';
import { styled, useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import { t } from 'i18next';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { firestore, auth } from '../../config/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import Home from './Home/Home';
import UserProfile from '../user/UserProfile';
import UserOrders from './orders/UserOrders';
import Settings from './Settings/Settings';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import EmailNotVerified from '../auth/EmailNotVerified';

const orangeTheme = createTheme({
  palette: {
    primary: {
      main: '#FFA500', // Orange
    },
    secondary: {
      main: '#FF8C00', // Dark Orange
    },
  },
});

const StyledTab = styled(Tab)(({ theme }) => ({
  minWidth: 'auto',
  color: theme.palette.common.white,
  opacity: 0.7,
  '&.Mui-selected': {
    color: theme.palette.common.white,
    opacity: 1,
    fontWeight: 'bold',
  },
  [theme.breakpoints.up('sm')]: {
    minWidth: 120,
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.common.white,
    height: 3,
  },
}));

const UserDashboard = ({ UserClient }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        UserClient = currentUser;
        const userDocRef = doc(firestore, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setUserData(userDocSnap.data());
        }
      } else {
        setCurrentUser(null);
        setUserData(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleLogoutClick = () => setLogoutDialogOpen(true);

  const handleLogoutConfirm = () => {
    signOut(auth)
      .then(() => setLogoutDialogOpen(false))
      .catch((error) => console.error('Error logging out:', error));
  };

  const renderContent = () => {
    switch (selectedTab) {
      case 0:
        return <Home currentUserUid={currentUser?.uid} />;
      case 1:
        return <UserProfile user={currentUser} userData={userData} />;
      case 2:
        return <UserOrders customerUid={currentUser?.uid} />;
      case 3:
        return <Settings user={currentUser} userData={userData} />;
      default:
        return <Home currentUserUid={currentUser?.uid} />;
    }
  };

  if (!UserClient.emailVerified) {
    return <EmailNotVerified />;
  }

  return (
    <ThemeProvider theme={orangeTheme}>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <CssBaseline />
        <AppBar position="static" elevation={0}>
          <Toolbar>
            <Avatar
              src={userData?.profilePicture || '/images/default-avatar.png'}
              alt={userData?.fullName || t('guest')}
              sx={{ width: 40, height: 40, mr: 2 }}
            />
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h6" component="div">
                {userData?.fullName || t('guest')}
              </Typography>
              {!isMobile && (
                <Typography variant="body2" color="inherit">
                  {currentUser?.email}
                </Typography>
              )}
            </Box>
            <IconButton color="inherit" onClick={handleLogoutClick} size="large">
              <ExitToAppIcon />
            </IconButton>
          </Toolbar>
          <StyledTabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{
              '& .MuiTabs-flexContainer': {
                justifyContent: 'space-around',
              },
            }}
          >
            <StyledTab icon={<HomeIcon />} label={!isMobile && t('home')} />
            <StyledTab icon={<PersonIcon />} label={!isMobile && t('my_profile')} />
            <StyledTab 
              icon={
                <Badge  color="secondary">
                  <ShoppingCartIcon />
                </Badge>
              } 
              label={!isMobile && t('my_orders')} 
            />
            <StyledTab icon={<SettingsIcon />} label={!isMobile && t('settings')} />
          </StyledTabs>
        </AppBar>
        <Container component="main" sx={{ flexGrow: 1, py: 3 }}>
          <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
            {renderContent()}
          </Paper>
        </Container>
        <Dialog
          open={logoutDialogOpen}
          onClose={() => setLogoutDialogOpen(false)}
          PaperProps={{
            style: {
              borderRadius: 12,
            },
          }}
        >
          <DialogTitle>{t('confirm_sign_out')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t('sign_out_confirmation')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setLogoutDialogOpen(false)}>{t('cancel')}</Button>
            <Button onClick={handleLogoutConfirm} color="error">
              {t('logout')}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default UserDashboard;