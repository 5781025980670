import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { firestore as db } from '../../../config/firebaseConfig';

const MarketplaceAdmin = () => {
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState('products');

  useEffect(() => {
    fetchProducts();
    fetchOrders();
  }, []);

  const fetchProducts = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'products'));
      const productsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProducts(productsData);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const fetchOrders = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'checkout'));
      const ordersData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setOrders(ordersData);
    } catch (error) {
      console.error('Error fetching orders:', error);
    } finally {
      setLoading(false);
    }
  };

  const updateProductVisibility = async (productId, isVisible) => {
    try {
      const productRef = doc(db, 'products', productId);
      await updateDoc(productRef, { isVisible: !isVisible });
      setProducts(products.map(p => p.id === productId ? {...p, isVisible: !isVisible} : p));
    } catch (error) {
      console.error('Error updating product visibility:', error);
    }
  };

  const deleteProduct = async (productId) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        await deleteDoc(doc(db, 'products', productId));
        setProducts(products.filter(p => p.id !== productId));
      } catch (error) {
        console.error('Error deleting product:', error);
      }
    }
  };

  const updateOrderStatus = async (orderId, newStatus) => {
    try {
      const orderRef = doc(db, 'checkout', orderId);
      await updateDoc(orderRef, { status: newStatus });
      setOrders(orders.map(o => o.id === orderId ? {...o, status: newStatus} : o));
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredOrders = orders.filter(order =>
    order.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    order.productName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="container">
      <h1 className="title">Marketplace Admin</h1>
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />
      <div className="tabs">
        <button
          className={`tab ${activeTab === 'products' ? 'active' : ''}`}
          onClick={() => setActiveTab('products')}
        >
          Products
        </button>
        <button
          className={`tab ${activeTab === 'orders' ? 'active' : ''}`}
          onClick={() => setActiveTab('orders')}
        >
          Orders
        </button>
      </div>
      {activeTab === 'products' && (
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Price</th>
              <th>Category</th>
              <th>Visibility</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.map((product) => (
              <tr key={product.id}>
                <td><img className='card' alt={product.name} width="50px" height="50px" src={product.images[0]}/>{product.name}</td>
                <td>{product.price} DT</td>
                <td>{product.category}</td>
                <td>
                  <span className={`badge ${product.isVisible ? 'visible' : 'hidden'}`}>
                    {product.isVisible ? 'Visible' : 'Hidden'}
                  </span>
                </td>
                <td>
                  <button
                    onClick={() => updateProductVisibility(product.id, product.isVisible)}
                    className="btn btn-outline"
                  >
                    Toggle Visibility
                  </button>
                  <button
                    onClick={() => deleteProduct(product.id)}
                    className="btn btn-danger"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {activeTab === 'orders' && (
        <table className="table">
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Customer</th>
              <th>Product</th>
              <th>Image</th>
              <th>Price</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.map((order) => (
              <tr key={order.id}>
                <td>{order.id}</td>
                <td>{order.fullName}</td>
                <td>{order.productName}</td>
                <td>
                  {order.image && (
                    <img src={order.image} alt={order.productName} width="50px" height="50px" />
                  )}
                </td>
                <td>{order.price} DT</td>
                <td>
                  <span className={`badge ${order.status.toLowerCase()}`}>
                    {order.status}
                  </span>
                </td>
                <td>
                  <select
                    value={order.status}
                    onChange={(e) => updateOrderStatus(order.id, e.target.value)}
                    className="select-status"
                  >
                    <option value="Pending">Pending</option>
                    <option value="Processing">Processing</option>
                    <option value="Shipped">Shipped</option>
                    <option value="Delivered">Delivered</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <style jsx>{`
        .container {
          max-width: 1200px;
          margin: 0 auto;
          padding: 20px;
        }
        .title {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 20px;
        }
        .search-input {
          width: 100%;
          padding: 10px;
          margin-bottom: 20px;
          border: 1px solid #ddd;
          border-radius: 4px;
        }
        .tabs {
          display: flex;
          margin-bottom: 20px;
        }
        .tab {
          padding: 10px 20px;
          border: none;
          background-color: #f0f0f0;
          cursor: pointer;
        }
        .tab.active {
          background-color: #007bff;
          color: white;
        }
        .table {
          width: 100%;
          border-collapse: collapse;
        }
        .table th, .table td {
          border: 1px solid #ddd;
          padding: 12px;
          text-align: left;
        }
        .table th {
          background-color: #f0f0f0;
          font-weight: bold;
        }
        .badge {
          padding: 5px 10px;
          border-radius: 4px;
          font-size: 12px;
        }
        .badge.visible {
          background-color: #28a745;
          color: white;
        }
        .badge.hidden {
          background-color: #dc3545;
          color: white;
        }
        .badge.pending {
          background-color: #ffc107;
          color: black;
        }
        .badge.processing {
          background-color: #17a2b8;
          color: white;
        }
        .badge.shipped {
          background-color: #007bff;
          color: white;
        }
        .badge.delivered {
          background-color: #28a745;
          color: white;
        }
        .btn {
          padding: 5px 10px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          margin-right: 5px;
        }
        .btn-outline {
          background-color: white;
          border: 1px solid #007bff;
          color: #007bff;
        }
        .btn-danger {
          background-color: #dc3545;
          color: white;
        }
        .select-status {
          padding: 5px;
          border-radius: 4px;
        }
        .product-image {
          width: 50px;
          height: 50px;
          object-fit: cover;
        }
      `}</style>
    </div>
  );
};

export default MarketplaceAdmin;