import React, { useState, useEffect, useMemo } from 'react';
import { Typography, CircularProgress, Tooltip, Fade } from '@mui/material';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { FaShoppingCart, FaMoneyBill, FaCalendarAlt, FaTruck } from 'react-icons/fa';
import { motion } from 'framer-motion';
import './Home.css';

const StatCard = ({ icon: Icon, title, value }) => {
  const { t } = useTranslation();
  
  return (
    <Tooltip title={t(`${title}_tooltip`)} placement="top" TransitionComponent={Fade} enterDelay={500} leaveDelay={200}>
      <motion.div
        className="stat-card"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="icon-wrapper">
          <Icon size={24} color="#FFFFFF" />
        </div>
        <Typography className="stat-title">{t(title)}</Typography>
        <Typography className="stat-value">{value}</Typography>
      </motion.div>
    </Tooltip>
  );
};

const Home = ({ currentUserUid }) => {
  const { t } = useTranslation();
  const [statistics, setStatistics] = useState(null);

  useEffect(() => {
    const fetchStatistics = async () => {
      if (!currentUserUid) {
        setStatistics(null);
        return;
      }

      const db = getFirestore();
      const ordersRef = collection(db, 'orders');
      const q = query(ordersRef, where('customerUid', '==', currentUserUid));
      
      try {
        const querySnapshot = await getDocs(q);

        const stats = querySnapshot.docs.reduce((acc, doc) => {
          const data = doc.data();
          acc.totalOrders += 1;
          acc.totalPriceInTnd += data.priceInTnd || 0;
          if (data.status === 'delivered') acc.ordersDelivered += 1;
          if (!acc.lastOrderDate || data.orderDate > acc.lastOrderDate) {
            acc.lastOrderDate = data.orderDate;
          }
          return acc;
        }, {
          totalOrders: 0,
          totalPriceInTnd: 0,
          ordersDelivered: 0,
          lastOrderDate: null,
        });

        setStatistics(stats);
      } catch (error) {
        console.error('Error fetching statistics:', error);
      }
    };

    fetchStatistics();
  }, [currentUserUid]);

  const statCards = useMemo(() => [
    { icon: FaShoppingCart, title: 'total_orders', value: statistics?.totalOrders },
    { icon: FaMoneyBill, title: 'total_spent_tnd', value: statistics?.totalPriceInTnd?.toFixed(2) },
    { icon: FaTruck, title: 'orders_delivered', value: statistics?.ordersDelivered },
    { icon: FaCalendarAlt, title: 'last_order_date', value: statistics?.lastOrderDate ? new Date(statistics.lastOrderDate.toDate()).toLocaleDateString() : 'لا يوجد' },
  ], [statistics]);

  return (
    <div className="dashboard">
      <div className="dashboard-paper">
        {/* <Typography className="dashboard-title">
          {t('dashboard_title')}
        </Typography> */}
        {statistics ? (
          <div className="stats-grid">
            {statCards.map((card, index) => (
              <StatCard key={card.title} {...card} />
            ))}
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
            <CircularProgress style={{ color: '#3498db' }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;