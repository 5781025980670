import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
// import { useAuth } from '../../contexts/AuthContext'; 
import { database,firestore } from '../../config/firebaseConfig';
import { ref, onValue, orderByChild, query, push } from 'firebase/database'; 
import { Box, AppBar, Toolbar, IconButton, Avatar, Typography, CircularProgress, TextField, Button, Container } from '@mui/material';
import { Facebook, Instagram, WhatsApp } from '@mui/icons-material';
import MessageItem from './MessageItem'; 
import { doc,getDoc } from 'firebase/firestore';
const UserMessagePage = ({ user }) => {
  const { t } = useTranslation();
  const currentUser = user; //useAuth();
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState('');
  const [fullName, setFullName] = useState('');
  const [loading, setLoading] = useState(true);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (currentUser) {
      const fetchUserData = async () => {
        try {
          const docRef = doc(firestore, 'users', user.uid); 
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();
            setFullName(userData.fullName || '');
        
          } 
        } catch (err) {
          console.error('Error fetching user data:', err);
         // setError('Error fetching user data');
        }
      };
      const messagesRef = query(ref(database, `messages/${currentUser.uid}`), orderByChild('timestamp'));
      onValue(messagesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const messagesArray = Object.entries(data).map(([key, value]) => ({ id: key, ...value }));
          messagesArray.sort((a, b) => a.timestamp - b.timestamp); // Oldest to newest
          setMessages(messagesArray);
        } else {
          setMessages([]);
        }
        setLoading(false);
        scrollToBottom();
      });
    }
  }, [currentUser]);

  const handleSendMessage = async () => {
    if (!messageText) return;
    const newMessage = {
      text: messageText,
      sender: currentUser.uid,
      receiver: "admin",
      seenBy: "00",
      timestamp: Date.now(),
    };
    const messagesRef = ref(database, `messages/${currentUser.uid}`);
    await push(messagesRef, newMessage);
    setMessageText('');
    scrollToBottom();
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container maxWidth="md">
      <AppBar position="static" sx={{ backgroundColor: 'orange' }}>
        <Toolbar>
          <Avatar src="/assets/logo/ajs_logo1.png" alt="AJS Logo" />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, marginLeft: 2 }}>
            {t('Customer Support')}
          </Typography>
          <IconButton color="inherit" onClick={() => window.open('https://www.facebook.com/profile.php?id=61555001870995')}>
            <Facebook />
          </IconButton>
          <IconButton color="inherit" onClick={() => window.open('https://www.instagram.com/amine.jameli.services/')}>
            <Instagram />
          </IconButton>
          <IconButton color="inherit" onClick={() => window.open('https://wa.me/your-whatsapp-number')}>
            <WhatsApp />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box my={3} sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
        {messages.length === 0 ? (
          <Typography>No messages found.</Typography>
        ) : (
          messages.map((msg) => (
            <MessageItem key={msg.id} message={msg} currentUser={currentUser} />
          ))
        )}
        <div ref={messagesEndRef} />
      </Box>
      <Box display="flex" alignItems="center" my={2}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Enter message"
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
        />
        <Button variant="contained" onClick={handleSendMessage} sx={{ marginLeft: 2, backgroundColor: 'orange' }}>
          Send
        </Button>
      </Box>
    </Container>
  );
};

export default UserMessagePage;
