
import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';

const MessageItem = ({ message, currentUser }) => {
  const isSentByCurrentUser = message.sender !== currentUser.uid;
  return (
    <Box
      display="flex"
      flexDirection={isSentByCurrentUser ? 'row-reverse' : 'row'}
      alignItems="center"
      my={1}
    >
      <Avatar src={isSentByCurrentUser ? 'images/user.png' : 'images/amine_jameli.png'} alt="User Avatar" />
      <Box
        ml={isSentByCurrentUser ? 2 : 0}
        mr={isSentByCurrentUser ? 0 : 2}
        p={2}
        bgcolor={isSentByCurrentUser ? 'lightblue' : 'orange'}
        color="white"
        borderRadius={isSentByCurrentUser ? '20px 0 20px 20px' : '0 20px 20px 20px'}
      >
        <Typography>{message.text}</Typography>
      </Box>
    </Box>
  );
};

export default MessageItem;
