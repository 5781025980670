import React, { useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import { ArrowRight } from 'lucide-react';
import useAnnouncements from '../../../hooks/useAnnouncements';
import animationData from '../../../assets/Announcement.json'; // Replace with your actual animation file
import './Announcements.css'; // Make sure you update the path
import Loading2 from '../../util/Loading2';

const AnnouncementSection = () => {
  const { announcements, loading } = useAnnouncements();
  const { t } = useTranslation();
  const [showAnnouncement, setShowAnnouncement] = useState(true); // Controls whether the section is shown

  const handleCloseAnnouncement = () => {
    setShowAnnouncement(false); // Close the announcement section
  };

  useEffect(() => {
    // Show the announcement section when the component mounts
    return () => {};
  }, []);

  if (!showAnnouncement) return null; // Hide the entire section if closed

  if (loading) {
    return (
      <div className="announcement-loading-container">
        <div className="announcement-spinner" role="status">
          <Loading2/>
        </div>
      </div>
    );
  }

  if (announcements.length === 0) {
    return null; // If no announcements, don't show the section
  }

  const handleClick = (url) => {
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <div className="floating-announcement-section">
      <button className="close-announcement-btn" onClick={handleCloseAnnouncement}>
        &times;
      </button>
      {/* Lottie Animation */}
      <Lottie animationData={animationData} loop autoplay className="lottie-animation" />

      {/* Announcement Content */}
      <Swiper
        modules={[Autoplay, Pagination]}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        loop={announcements.length > 1}
        className="announcement-swiper"
      >
        {announcements.map((announcement) => (
          <SwiperSlide key={announcement.id}>
            <div className="announcement-alert" onClick={() => handleClick(announcement.url)}>
              <p className="announcement-text">{announcement.text}</p>
              {announcement.url && <ArrowRight className="announcement-icon" />}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default AnnouncementSection;