import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { firestore } from '../config/firebaseConfig';
import Lottie from 'react-lottie';
import animationData from '../assets/AJS_LOADING.json';
import FriendlyErrorDialog from './FriendlyErrorDialog';
import Header from './home/NavBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { LocalShipping, CheckCircle, Search , WarningAmber  } from '@mui/icons-material';
import { Typography } from '@mui/material';

const TrackOrderScreen = () => {
  const { t } = useTranslation();
  const [orderCode, setOrderCode] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const [orderDate, setOrderDate] = useState(null);
  const [updateDate, setUpdateDate] = useState(null);
  const [customerName, setCustomerName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const controls = useAnimation();

  useEffect(() => {
    console.log(customerName);
  }, [customerName]);

  const calculateEstimatedDeliveryDate = (updateDate, status) => {
    const transitToGermanyDays = 30;
    const localInGermanyDays = 25;
    const transitToTunisiaDays = 20;
    const localInTunisiaDays = 10;

    let totalDays = 0;

    switch (status) {
      case 'in transit to Germany':
        totalDays = transitToGermanyDays ;
        break;
      case 'in our local in Germany':
        totalDays = localInGermanyDays ;
        break;
      case 'in transit to Tunisia':
        totalDays = transitToTunisiaDays ;
        break;
      case 'in our local in Tunisia':
        totalDays = localInTunisiaDays;
        break;
      case 'delivered':
        return updateDate;
      default:
        totalDays = transitToGermanyDays ;
    }

    return new Date(updateDate.getTime() + totalDays * 24 * 60 * 60 * 1000);
  };

  const trackOrder = async () => {
    setIsLoading(true);
    setErrorMessage('');
    setShowBanner(false);

    await controls.start({
      scale: [1, 1.2, 1],
      rotate: [0, 360, 0],
      transition: { duration: 1 }
    });

    if (orderCode.trim()) {
      try {
        const ordersRef = collection(firestore, 'orders');
        const q = query(ordersRef, where('orderCode', '==', orderCode.trim()));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const orderDoc = querySnapshot.docs[0].data();
          setOrderStatus(orderDoc.status);
          setOrderDate(orderDoc.orderDate.toDate());
          setUpdateDate(orderDoc.updateDate.toDate());
          setCustomerName(orderDoc.customerName);
          setErrorMessage('');
          setShowBanner(true);
        } else {
          setOrderStatus(t('order_not_found'));
          setErrorMessage(t('order_not_found'));
        }
      } catch (error) {
        setErrorMessage(t('error_fetching_order', { error: error.message }));
      }
    } else {
      setErrorMessage(t('enter_order_code'));
    }
    setIsLoading(false);
  };

  const renderStepIndicator = (stage, isActive, isCurrentStage, index) => (
    <motion.div 
      className="d-flex align-items-center mb-2"
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
    >
      <motion.div
        className={`d-inline-block me-2 rounded ${
          isActive ? (orderStatus !== 'delivered' ? 'bg-warning' : 'bg-success') : 'bg-secondary'
        }`}
        style={{ height: '50px', width: '10px' }}
        initial={{ height: 0 }}
        animate={{ height: '50px' }}
        transition={{ duration: 0.5, delay: 0.2 + index * 0.1 }}
      />
      <div className="flex-grow-1 d-flex justify-content-between">
        <motion.span 
          className={`text-${isActive ? 'dark' : 'muted'} fw-${isActive ? 'bold' : 'normal'}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.3 + index * 0.1 }}
        >
          {stage}
        </motion.span>
        {isCurrentStage && updateDate && (
          <motion.div 
            className="px-2 py-1 bg-warning rounded text-dark" 
            style={{ fontSize: '12px' }}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3, delay: 0.5 + index * 0.1 }}
          >
            {updateDate.toLocaleString()}
          </motion.div>
        )}
      </div>
    </motion.div>
  );

  const renderTrackingUI = () => {
    const stages = [
      'in transit to Germany',
      'in our local in Germany',
      'in transit to Tunisia',
      'in our local in Tunisia',
      'delivered',
    ];
    const currentStageIndex = stages.indexOf(orderStatus || '');
  
    return (
      <div>
        {orderStatus === 'pending' && (
          <motion.div
            className="alert alert-info text-center mb-4"
            initial={{ opacity: 0, scale: 0.8, y: -20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.8, y: -20 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            {t('order_pending_updates_coming_soon')}
          </motion.div>
        )}
        {stages.map((stage, index) => (
          <motion.div
            key={index}
            className="mb-2"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 * index }}
          >
            {renderStepIndicator(
              t(stage.replaceAll(' ', '_').toLowerCase()), 
              index <= currentStageIndex, 
              index === currentStageIndex, 
              index
            )}
          </motion.div>
        ))}
      </div>
    );
  };
  const renderDelayMessage = (estimatedDeliveryDate) => {
    const currentDate = new Date();
    if (currentDate > estimatedDeliveryDate && orderStatus !== 'delivered') {
      const delayDays = Math.ceil((currentDate - estimatedDeliveryDate) / (1000 * 60 * 60 * 24));
      return (
        <motion.div
          className="alert alert-warning mb-3"
          role="alert"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="d-flex align-items-center">
            <motion.div 
              initial={{ rotate: -45 }}
              animate={{ rotate: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <WarningAmber />
            </motion.div>
            <div className="ms-2">
              <strong>
                {t('delay_message', { days: delayDays })}
              </strong>
            </div>
          </div>
        </motion.div>
      );
    }
    return null;
  };
  const renderEstimatedDeliveryBanner = () => {
    if (!updateDate || !orderStatus) return null;
  
    const estimatedDeliveryDate = calculateEstimatedDeliveryDate(updateDate, orderStatus);
  
    return (
      <>
        <motion.div
          className={`alert ${orderStatus !== 'delivered' ? 'alert-warning' : 'alert-success'} mb-3`}
          role="alert"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: showBanner ? 1 : 0, y: 0 }}
          transition={{ duration: 0.5 }}
          style={{ padding: '1rem', borderRadius: '0.5rem', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}
        >
          <div className="d-flex align-items-center">
            <motion.div
              initial={{ rotate: -45 }}
              animate={{ rotate: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              {orderStatus !== 'delivered' ? <LocalShipping /> : <CheckCircle />}
            </motion.div>
            <div className="ms-2">
              <strong style={{ fontSize: '1.1rem', color: '#333', lineHeight: '1.5' }}>
                {orderStatus !== 'delivered' ? (
                  <div>
                    <Typography variant="body1" component="div" style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                      {t('order_created_date', { date: orderDate.toLocaleDateString() })}
                    </Typography>
                    <br />
                    <Typography variant="body1" component="div" style={{ fontWeight: 'bold', fontSize: '1.2rem', color: '#ff9800' }}>
                      {t('estimated_delivery_date', { date: estimatedDeliveryDate.toLocaleDateString() })}
                    </Typography>
                  </div>
                ) : (
                  <Typography variant="body1" component="div" style={{ fontWeight: 'bold', fontSize: '1.2rem', color: '#4caf50' }}>
                    {t('order_delivered_at', { date: updateDate.toLocaleDateString() })}
                  </Typography>
                )}
              </strong>
            </div>
          </div>
        </motion.div>
        {renderDelayMessage(estimatedDeliveryDate)}
      </>
    );
  };
  return (
    <div className="container py-4">
      <motion.div 
        className="text-center mb-4"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <img src="images/tracking.png" alt="Tracking" height="200" />
      </motion.div>
      <motion.div 
        className="bg-white rounded card-with-elevation p-4 mb-4"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <div className="input-group mb-3">
          <input
            type="text"
            value={orderCode}
            onChange={(e) => setOrderCode(e.target.value)}
            placeholder={t('enter_order_code')}
            className="form-control"
          />
          <motion.button
            className="btn btn-warning"
            onClick={trackOrder}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <motion.div animate={controls}>
              <Search />
            </motion.div>
          </motion.button>
        </div>
      </motion.div>
      <AnimatePresence>
        {isLoading && (
          <motion.div 
            className="text-center mb-4"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.3 }}
          >
            <Lottie options={{ animationData, loop: true }} height={240} width={240} />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!isLoading && errorMessage && (
          <motion.div 
            className="mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            <FriendlyErrorDialog errorMessage={errorMessage} onRetry={trackOrder} />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!isLoading && orderStatus && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
          >
            {renderEstimatedDeliveryBanner()}
            <motion.div 
              className="bg-white rounded card-with-elevation p-4 mb-4"
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <motion.div 
                className={`p-3 rounded border ${orderStatus !== 'delivered' ? 'border-warning bg-warning' : 'border-success bg-success'} text-white`}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.4 }}
              >
                <strong>
                  {t('hi_customer', { name: customerName || t('customer') })}
                </strong>
              </motion.div>
              <div className="mt-4">
                {renderTrackingUI()}
              </div>
              {orderDate && (
                <motion.div 
                  className={`p-3 mt-3 rounded border ${orderStatus !== 'delivered' ? 'border-warning bg-warning' : 'border-success bg-success'} text-white`}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: 0.6 }}
                >
                  <div>{t('order_created_date', { date: orderDate.toLocaleDateString() })}</div>
                  {/* {orderStatus !== 'delivered' && (
                    <div>
                      {t('estimated_delivery_date', { date: calculateEstimatedDeliveryDate(updateDate, orderStatus).toLocaleDateString() })}
                    </div>
                  )} */}
                </motion.div>
              )}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default TrackOrderScreen;