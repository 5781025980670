import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, firestore } from './config/firebaseConfig';

import Homepage from './components/home/homepage';
import TrackOrderScreen from './components/TrackOrder';
import AuthForm from './components/auth/AuthScreen'; 
import FloatingSupportButton from './components/FloatingSupportButton';
import UserMessagePage from './components/chat/UserMessagePage';
import AddImages from './components/dev/AddImages';
import UserDashboard from './components/dashboard/UserDashboard';
import Loading from './components/util/Loading';
import SheinProductSearch from './components/scarping/shein/shein_product_search';
import SheinProductChecker from './components/scarping/sheinv2/sheinv2';
import AdminDashboard from './components/admin/AdminDashboard';
import FeedbackScreen from './components/dashboard/Feedbacks/Feedback';
import MarketPlace from './components/marketplace/MarketPlace';
import AddProduct from './components/marketplace/AddProduct';
import ProductDetail from './components/marketplace/ProductDetail';
import MarketplaceAdmin from './components/admin/marketplace/MarketplaceAdmin';
import AdminDashboardV2 from './components/adminV2/AdminDashboard';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const userDoc = await getDoc(doc(firestore, 'users', currentUser.uid));
        const userData = userDoc.data();
        setIsAdmin(userData?.sudo || false);
      } else {
        setUser(null);
        setIsAdmin(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <Loading />; 
  }

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Homepage user={user} />} />
          <Route path="/tracking" element={<TrackOrderScreen />} />
          <Route path="/feedbacks" element={<FeedbackScreen user={user} />} />
          {/* <Route path="/sheinTunisia" element={<SheinProductChecker />} /> */}
          <Route path="/u" element={<AuthForm />} />
          {/* <Route path="/shop" element={<MarketPlace />} />
          <Route path="/shop/:id" element={<ProductDetail />}/> */}
          <Route path="/add-product" element={<AddProduct />} />
          <Route path="/root-market" element={<MarketplaceAdmin />} />
          <Route path="/add" element={<AddImages />} />
          <Route path="/chat" element={<UserMessagePage user={user} />} />
          <Route path="/root" element={isAdmin ? <AdminDashboard /> : <AuthForm />} />
          {/* <Route path="/root-v2" element={isAdmin ? <AdminDashboardV2 /> : <AuthForm />} /> */}
          <Route path="/me" element={user ? <UserDashboard UserClient={user} /> : <AuthForm />} />
        </Routes>
      </Router>
      <FloatingSupportButton />
    </div>
  );
}

export default App;
