import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, CircularProgress } from '@mui/material';
import { storage, firestore } from '../../config/firebaseConfig'; 
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc } from 'firebase/firestore';

const AddImages = () => {
  const [label, setLabel] = useState('');
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!image) return;

    setLoading(true);
    try {
      const storageRef = ref(storage, `what_could_you_buy/${image.name}`);
      await uploadBytes(storageRef, image);
      const url = await getDownloadURL(storageRef);
      setLoading(false);
      console.log(url);
      return url;
    } catch (error) {
      console.error('Error uploading image:', error);
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let url = '';
    if (image) {
      url = await handleUpload();
      if (!url) {
        console.error('Failed to upload image');
        return;
      }
    }

    const formData = {
      attachmentUrl: url,
      label
    };

    try {
      await addDoc(collection(firestore, 'WhatToBuy'), formData);
      console.log('Document successfully written!');
      // Reset form fields
      setLabel('');
      setImage(null);
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Fill Data Form
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          label="Label"
          variant="outlined"
          value={label}
          onChange={(e) => setLabel(e.target.value)}
          fullWidth
          required
        />
        <Button variant="contained" component="label">
          Pick Image
          <input type="file" hidden onChange={handleImageChange} />
        </Button>
        {image && <Typography>{image.name}</Typography>}
        {loading ? <CircularProgress /> : (
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default AddImages;
