import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { firestore } from '../../config/firebaseConfig';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { 
  Button, 
  TextField, 
  Typography, 
  Card, 
  CardContent, 
  Grid, 
  Box, 
  Avatar, 
  Snackbar,
  CircularProgress
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: '600px',
  margin: 'auto',
  padding: theme.spacing(3),
  boxShadow: '0 4px 20px rgba(255, 152, 0, 0.2)',
  borderRadius: '12px',
  background: 'linear-gradient(145deg, #fff, #fff6e5)',
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(15),
  height: theme.spacing(15),
  margin: 'auto',
  marginBottom: theme.spacing(3),
  border: `4px solid ${theme.palette.primary.main}`,
  boxShadow: '0 4px 10px rgba(255, 152, 0, 0.3)',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  backgroundColor: '#FFA000',
  color: '#FFF',
  '&:hover': {
    backgroundColor: '#FF8F00',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#FFB74D',
    },
    '&:hover fieldset': {
      borderColor: '#FFA000',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FF8F00',
    },
  },
}));

const UserProfile = ({ user, userData }) => {
  const { t } = useTranslation();
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    if (user && userData) {
      setFullName(userData.fullName || '');
      setEmail(user.email || '');
      setPhoneNumber(userData.phoneNumber || '');
      setAddress(userData.address || '');
    }
  }, [user, userData]);

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    if (!user) return;

    setLoading(true);
    try {
      const userDocRef = doc(firestore, 'users', user.uid);
      await updateDoc(userDocRef, {
        fullName,
        phoneNumber,
        address,
      });

      // Fetch updated user data
      const updatedUserDoc = await getDoc(userDocRef);
      const updatedUserData = updatedUserDoc.data();

      // Update local state with new data
      setFullName(updatedUserData.fullName || '');
      setPhoneNumber(updatedUserData.phoneNumber || '');
      setAddress(updatedUserData.address || '');

      setSnackbar({ open: true, message: t('profile_updated_successfully'), severity: 'success' });
    } catch (error) {
      console.error('Error updating profile: ', error);
      setSnackbar({ open: true, message: t('error_updating_profile'), severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ padding: { xs: '16px', sm: '24px', md: '32px' }, backgroundColor: '#FFF3E0', minHeight: '100vh' }}>
      <StyledCard elevation={3}>
        <CardContent>
          <StyledAvatar src={userData?.profilePicture || 'images/amine_jameli.png'} alt={fullName} />
          <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ fontWeight: 'bold', color: '#E65100' }}>
            {t('my_profile')}
          </Typography>

          <form onSubmit={handleUpdateProfile}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <StyledTextField
                  fullWidth
                  label={t('full_name')}
                  variant="outlined"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                  InputProps={{
                    startAdornment: <EditIcon color="action" sx={{ mr: 1 }} />,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  fullWidth
                  label={t('email')}
                  variant="outlined"
                  value={email}
                  disabled
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  fullWidth
                  label={t('phone')}
                  variant="outlined"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  InputProps={{
                    startAdornment: <EditIcon color="action" sx={{ mr: 1 }} />,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  fullWidth
                  label={t('address')}
                  variant="outlined"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  InputProps={{
                    startAdornment: <EditIcon color="action" sx={{ mr: 1 }} />,
                  }}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <StyledButton
                type="submit"
                variant="contained"
                disabled={loading}
                startIcon={loading ? <CircularProgress size={20} /> : null}
              >
                {loading ? t('updating') : t('update_profile')}
              </StyledButton>
            </Box>
          </form>
        </CardContent>
      </StyledCard>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <MuiAlert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default UserProfile;