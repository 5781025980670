import React, { useState } from 'react';
import {
    Alert,
    Box,
    CardContent,
    Checkbox,
    CircularProgress,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { CloudUpload, Storage } from '@mui/icons-material';
import { generateBackupFiles } from './Data_Managements/Backup_Data';
import { StyledBox, StyledCard, StyledTableCell, StyledTableRow , GradientText, IconBox, StyledButton } from './Styles/GetTheme';


const BackupManager = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [selectedCollections, setSelectedCollections] = useState({
    feedbacks: false,
    orders: false,
    shippings: false,
    users: false,
    newsletter: false
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSelectedCollections({
      ...selectedCollections,
      [name]: checked,
    });
  };

  const handleBackup = async () => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    const collectionsToBackup = Object.keys(selectedCollections).filter(
      (collection) => selectedCollections[collection]
    );

    if (collectionsToBackup.length === 0) {
      setError("Please select at least one collection to back up.");
      setLoading(false);
      return;
    }

    try {
      await generateBackupFiles(collectionsToBackup);
      setSuccess(true);
    } catch (err) {
      setError("Error generating backup. Please try again.");
      console.error("Backup generation error:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledBox>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <IconBox color='orange'>
          <Storage sx={{ fontSize: 30 }} />
        </IconBox>
        <GradientText variant="h4" component="h1" sx={{ ml: 2 }}>
          Backup Manager
        </GradientText>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom fontWeight="bold">
                Select Collections to Backup
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Collection</StyledTableCell>
                      <StyledTableCell>Select</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(selectedCollections).map((collection) => (
                      <StyledTableRow key={collection}>
                        <StyledTableCell>
                          {collection.charAt(0).toUpperCase() + collection.slice(1)}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Checkbox
                            checked={selectedCollections[collection]}
                            onChange={handleCheckboxChange}
                            name={collection}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={handleBackup}
                    startIcon={<CloudUpload />}
                    size="large"
                  >
                    Generate Backup
                  </StyledButton>
                )}
              </Box>

              {error && (
                <Alert severity="error" sx={{ mt: 3 }}>
                  {error}
                </Alert>
              )}
              {success && (
                <Alert severity="success" sx={{ mt: 3 }}>
                  Backup generated successfully!
                </Alert>
              )}
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export default BackupManager;