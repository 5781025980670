import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './Footer.css'; 

const Footer = () => {
  return (
    <footer className="footer_container">

      <section className="info_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-4">
              <div className="info_detail">
                <h4>حولنا</h4>
                <p>
                  نقدم حلول شحن<br />
                  من Aliexpress و Shein و Temu<br />
                  إلى تونس ✈️🇹🇳
                </p>

              </div>
            </div>
            <div className="col-md-12 col-lg-4">
              <div className="info_contact">
                <h4>اتصل بنا</h4>
                <div className="contact_link_box">
                  <a href="https://www.google.com/maps/search/?api=1&query=Tunisia" target="_blank" rel="noopener noreferrer">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    <span>تونس</span>
                  </a>
                  <a href="tel:+21628182762">
                    <i className="fa fa-phone" aria-hidden="true"></i>
                    <span>اتصل على 28 182 762</span>
                  </a>
                  <a href="mailto:contact@aminejameli.tn">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                    <span>contact@aminejameli.tn</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4">
              <div className="info_social">
                <div className="social_links">
                  <a href="https://www.facebook.com/profile.php?id=61564908999870" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                  <a href="https://wa.me/21628182762" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp">
                    <i className="fa fa-whatsapp" aria-hidden="true"></i>
                  </a>
                  <a href="https://www.tiktok.com/@amine.jameli.services" target="_blank" rel="noopener noreferrer" aria-label="TikTok">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="24" height="24">
                      <path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" fill="#FFFFFF" />
                    </svg>
                  </a>
                  <a href="https://www.instagram.com/amine.jameli.services" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="footer_section">
        <div className="container text-center">
          <p>
            &copy; <span id="displayYear">{new Date().getFullYear()}</span> جميع الحقوق محفوظة لـ
            <a href="https://cloudsoftware.tn/" target="_blank" rel="noopener noreferrer"><strong> AJS & CAS INC.</strong></a>
          </p>
        </div>
      </section>
 
    </footer>
  );
};

export default Footer;
